// Generated by Framer (92d6359)

import { addFonts, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {TeX1oCqyW: {hover: true}};

const serializationHash = "framer-N68Kz"

const variantClassNames = {TeX1oCqyW: "framer-v-1b5kojg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "TeX1oCqyW", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{hash: ":wtXL_OzbL", webPageId: "augiA20Il"}} nodeId={"TeX1oCqyW"} openInNewTab={false} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1b5kojg", className, classNames)} framer-10zkeb4`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"TeX1oCqyW"} ref={ref ?? ref1} style={{backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, ...style}} variants={{"TeX1oCqyW-hover": {backgroundColor: "rgba(255, 255, 255, 0.05)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4}}} {...addPropertyOverrides({"TeX1oCqyW-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7T3V0Zml0LXJlZ3VsYXI=", "--framer-font-family": "\"Outfit\", \"Outfit Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Process</motion.p></React.Fragment>} className={"framer-1dy8hem"} fonts={["GF;Outfit-regular"]} layoutDependency={layoutDependency} layoutId={"SV5aod4Ey"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-N68Kz.framer-10zkeb4, .framer-N68Kz .framer-10zkeb4 { display: block; }", ".framer-N68Kz.framer-1b5kojg { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 25px; justify-content: center; overflow: visible; padding: 3px 4px 3px 4px; position: relative; text-decoration: none; width: min-content; }", ".framer-N68Kz .framer-1dy8hem { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-N68Kz.framer-1b5kojg { gap: 0px; } .framer-N68Kz.framer-1b5kojg > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-N68Kz.framer-1b5kojg > :first-child { margin-left: 0px; } .framer-N68Kz.framer-1b5kojg > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 25
 * @framerIntrinsicWidth 56
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"fhC84U16m":{"layout":["auto","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerszojIQ1lV: React.ComponentType<Props> = withCSS(Component, css, "framer-N68Kz") as typeof Component;
export default FramerszojIQ1lV;

FramerszojIQ1lV.displayName = "nav items";

FramerszojIQ1lV.defaultProps = {height: 25, width: 56};

addFonts(FramerszojIQ1lV, [{explicitInter: true, fonts: [{family: "Outfit", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC1C4S-EiAou6Y.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})